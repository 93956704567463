import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  UnsavedChangesDataState,
  ModalActionType,
  OpenConfirm,
} from "../../models/store/unsavedChangesDataState";

const initialState: UnsavedChangesDataState = {
  isChanged: false,
  isFiltersCustomValuesModified: false,
  hasOrderAgesError: false,
  openModal: false,
  modalConfirmedActionType: ModalActionType.navigate,
  modalConfirmedActionData: "",
};

const UnsavedChangesDataSlice = createSlice({
  name: "changeData",
  initialState,
  reducers: {
    isChanged: (state, action: PayloadAction<boolean>) => {
      state.isChanged = action.payload;
    },
    isFiltersCustomValuesModified: (state, action: PayloadAction<boolean>) => {
      state.isFiltersCustomValuesModified = action.payload;
    },
    hasOrderAgesError: (state, action: PayloadAction<boolean>) => {
      state.hasOrderAgesError = action.payload;
    },
    openConfirmModal: (state, action: PayloadAction<OpenConfirm>) => {
      state.openModal = true;
      state.modalConfirmedActionType = action.payload.modalConfirmedActionType;
      state.modalConfirmedActionData = action.payload.modalConfirmedActionData;
    },
    closeModal: (state) => {
      state.openModal = false;
    },
  },
});

export const {
  isChanged,
  isFiltersCustomValuesModified,
  hasOrderAgesError,
  openConfirmModal,
  closeModal,
} = UnsavedChangesDataSlice.actions;

export default UnsavedChangesDataSlice.reducer;
