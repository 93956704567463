import appConfig from "../../../settings/appConfig";
import { get, put } from "../../http/apiClient";
import { OrderQueryModel } from "../../../models/ordersIntegration/OrderQueryModel";
import { OrderResponseModel } from "../../../models/ordersIntegration/OrderResponseModel";
import { UpdateOrdersRequest } from "../../../models/ordersIntegration/UpdateOrdersRequest";
import PaginationModel from "../../../models/common/paginationModel";

export const getOrders = async (
  filter: OrderQueryModel
): Promise<PaginationModel<OrderResponseModel>> => {
  return await get<PaginationModel<OrderResponseModel>>(
    `${appConfig.baseUrl}${appConfig.URLS.OrdersIntegration.Orders}`,
    undefined,
    filter
  );
};

export const updateOrders = async (
  updateRequest: UpdateOrdersRequest
): Promise<void> => {
  await put(
    `${appConfig.baseUrl}${appConfig.URLS.OrdersIntegration.Orders}`,
    updateRequest
  );
};
