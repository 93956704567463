import FilterLookup from "../../../../../../models/filters/filterLookup";
import QueueFilterSkeleton from "../../../../../../models/filters/queueFilterSkeleton";
import { FormFieldEnum } from "../../../../../../models/enums/formFieldEnum";
import RadioFilter from "./fields/radioFilter";
import { Grid } from "@mui/material";

import FilterInputProps from "../../../../../../models/filters/filterInputProps";
import CompoenentLoader from "../../../../../../common/loader/componentLoader";
import { QueueFiltersProps } from "../../../../../../models/filters/queueFiltersProps";
import QueueDetailsStyles from "../../../../queueDetails.style";
import DropDownFilter from "./fields/dropdownFilter";
import appConfig from "../../../../../../settings/appConfig";

const OrderQueueFilters = ({
  queueFilters,
}: {
  queueFilters: QueueFiltersProps;
}) => {
  const {
    isLoading,
    filterLookupList,
    filters,
    getFilterValue,
    addOrUpdateFilterValue,
    setSavedSelectedFilterValues,
  } = queueFilters;

  return (
    <CompoenentLoader isOpen={isLoading}>
      {filters && filters.length > 0 && (
        <Grid container mt={2} sx={QueueDetailsStyles.greyCard}>
          <Grid p={2} m={2} container>
            {filterLookupList?.map((data: FilterLookup, inx) => {
              const filter = filters?.find((f) => f.filterId === data.filterId);
              let filterProps: FilterInputProps = {
                title: filter?.filterName,
                id: filter?.filterId?.toString(),
                label: filter?.filterName,
                options: [
                  ...data.lookupList.filter(item => item.id !== appConfig.unknownFilterValueId),
                  ...data.lookupList.filter(item => item.id === appConfig.unknownFilterValueId)
                ],
                key: filter?.filterId?.toString(),
                handleChange: (value: string) => {
                  setSavedSelectedFilterValues(undefined);
                  addOrUpdateFilterValue(value, filter?.filterId ?? 0, filters);
                },
                selectedValue: getFilterValue(filter?.filterId ?? 0) ?? "",
              };
              let getFilterItem = (
                filter: QueueFilterSkeleton | undefined,
                filterProps: FilterInputProps
              ) => {
                switch (filter?.filterType) {
                  case FormFieldEnum.Dropdown:
                    return <DropDownFilter {...filterProps} />;
                  case FormFieldEnum.Radio:
                    return <RadioFilter {...filterProps} />;
                  default:
                    return null;
                }
              };
              return (
                <Grid
                  item
                  xs={12}
                  md={12}
                  p={1}
                  lg={12 / filters.length}
                  key={filter?.filterName.toString() ?? inx}
                >
                  {getFilterItem(filter, filterProps)}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      )}
    </CompoenentLoader>
  );
};
export default OrderQueueFilters;
