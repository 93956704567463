import "../../css/iframe.css";
import {
  Box,
  Button,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Title, SubTitle, badge, pagination, editButton } from "./queuesSetup.style";

import {
  tableContainer,
  tableHeader,
  tableRow,
  editColor,
} from "../../common/styles/tableStyle.style";
import Status from "../../models/status/status";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getAllStatuses } from "../../core/services/status/statusService";
import ComponentLoader from "../../common/loader/componentLoader";
import appConfig from "../../settings/appConfig";
import SortTableHeader, {
  SortDirection,
} from "../../models/common/sortTableHeader";
import SortIcon from "../../common/sortTableIcon/sortIcon";
import { useNavigate } from "react-router-dom";

export default function QueuesSetupPage() {
  const [statuses, setStatuses] = useState<Status[]>([]);
  const [currentStatuses, setCurrentStatuses] = useState<Status[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [sortField, setSortField] = useState(0);
  const [sortDir, setSortDir] = useState<SortDirection>("asc");
  const navigate = useNavigate();
  const tableHeaders: SortTableHeader[] = [
    {
      id: 1,
      label: "Synced",
      hasSort: true,
      propName: "isSynced",
      width: "15%",
    },
    { id: 2, label: "Edit", hasSort: false, width: "15%" },
    {
      id: 3,
      label: "Part Status",
      hasSort: true,
      propName: "statusName",
      width: "70%",
    },
  ];
  const queueSetupPageCount = appConfig.queueSetupPageCount;

  const getStatuses = useCallback(async () => {
    try {
      setIsLoading(true);
      const allStatuses = await getAllStatuses();
      setStatuses(allStatuses);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      throw e;
    }
  }, []);

  const updateCurrentStatuses = useCallback(
    (page: number) => {
      const start = (page - 1) * queueSetupPageCount;
      const end = start + queueSetupPageCount;
      setCurrentStatuses([...statuses.slice(start, end)]);
    },
    [statuses]
  );

  useEffect(() => {
    getStatuses();
  }, [getStatuses]);

  useEffect(() => {
    updateCurrentStatuses(page);
  }, [updateCurrentStatuses, page]);

  const handleChangePage = (event: any, value: number) => {
    setPage(value);
  };

  const sortStatuses = (property: number, dir: SortDirection) => {
    setPage(1);
    const headerName = tableHeaders.find((p) => p.id === property)?.propName;

    if (headerName) {
      let sortedStatuses;
      if (dir === "asc")
        sortedStatuses = [...statuses].sort((a, b) =>
          String((a as any)[headerName]).localeCompare((b as any)[headerName])
        );
      else
        sortedStatuses = [...statuses].sort((a, b) =>
          String((b as any)[headerName]).localeCompare((a as any)[headerName])
        );

      setStatuses([...sortedStatuses]);
      setSortDir(dir);
      setSortField(property);
    }
  };

  const handleToggleSort = (key: number) => {
    setPage(1);
    const isAsc = key == sortField && sortDir == "asc";
    if (isAsc) sortStatuses(key, "desc");
    else sortStatuses(key, "asc");
  };

  const totalNumberOfPages = useMemo(() => {
    const totalRows = statuses?.length ?? 0;
    const x =
      totalRows % queueSetupPageCount > 0
        ? Math.floor(totalRows / queueSetupPageCount) + 1
        : Math.floor(totalRows / queueSetupPageCount);

    return x;
  }, [statuses.length, queueSetupPageCount]);

  return (
    <Box>
      <Typography sx={Title}>Queues Setup</Typography>
      <Grid container alignItems={"end"}>
        <Grid item>
          <Typography sx={SubTitle}>Active Part Statuses In MR8</Typography>
        </Grid>
        <Grid item>
          <Box sx={badge}>{statuses.length}</Box>
        </Grid>
      </Grid>

      <ComponentLoader isOpen={isLoading}>
        <TableContainer sx={tableContainer} component={Paper}>
          <Table
            data-auto-test-id="remaining-order-table"
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                {tableHeaders.map((item) => {
                  return (
                    <TableCell
                      sx={{ ...tableHeader, width: item.width }}
                      key={item.id}
                    >
                      {item.hasSort ? (
                        <Grid
                          container
                          alignItems={"center"}
                          sx={{ cursor: "pointer" }}
                          onClick={() => handleToggleSort(item.id)}
                        >
                          <Grid item style={{ marginRight: "5px" }}>
                            {item.label}
                          </Grid>
                          <SortIcon
                            itemId={item.id}
                            sortDir={sortDir}
                            sortField={sortField}
                          />
                        </Grid>
                      ) : (
                        item.label
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {currentStatuses.map((row) => (
                <TableRow key={row.statusId}>
                  <TableCell component="th" scope="row">
                    <Typography sx={tableRow}>
                      {row.isSynced ? "ON" : "OFF"}
                    </Typography>
                  </TableCell>

                  <TableCell component="th" scope="row" sx={editColor}>
                    <Button
                      onClick={() => navigate(`/queueSetting/${row.statusId}`)}
                      data-testid={`edit-${row.statusId}`}
                      sx={editButton}
                    >
                      Edit
                    </Button>
                  </TableCell>

                  <TableCell component="th" scope="row">
                    <Typography sx={tableRow}>{row.statusName}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box display="flex" justifyContent="end" sx={{ margin: "3px" }}>
            <Pagination
              sx={pagination}
              data-auto-test-id="statuses-table-pagination"
              disabled={isLoading}
              count={totalNumberOfPages}
              hideNextButton
              hidePrevButton
              showFirstButton
              showLastButton
              page={page}
              shape={"rounded"}
              onChange={handleChangePage}
            />
          </Box>
        </TableContainer>
      </ComponentLoader>
    </Box>
  );
}
