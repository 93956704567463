import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, CircularProgress, Tabs, Typography } from "@mui/material";
import { statusTitle, submitButton } from "./queueSetting.style";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import StyledTab from "../../../common/styledTab";
import TabPanel from "../../../common/tabPanel";
import GeneralTabContent from "./components/generalTabContent";
import FiltersTabContent from "./components/filterTabContent/filtersTabContent";

import {
  getQueueSetting,
  updateQueueSetting,
} from "../../../core/services/status/statusService";
import QueueSettingModel from "../../../models/status/queueSettingModel";
import QueueNotFound from "../../queue/components/queueNotFound/queueNotFound";
import ValidationError from "../../../models/status/validationError";
import { useDispatch, useSelector } from "react-redux";
import { showSnackbarBySeverity } from "../../../redux/slices/snackbarSlice";
import AppState from "../../../redux/appStore";
import {
  isChanged,
  isFiltersCustomValuesModified,
  openConfirmModal,
} from "../../../redux/slices/unsavedChangesDataSlice";
import FilterModel from "../../../models/filters/filterModel";
import {
  getFilters,
  updateOrderAges,
} from "../../../core/services/filter/filterService";
import appConfig from "../../../settings/appConfig";
import { getAllQueues } from "../../../core/services/queue/queueService";
import { setQueues } from "../../../redux/slices/queueSlice";
import QueuesGroup from "../../../models/queue/queueGroup";
import AttributesTabContent from "./components/attributeTabContent/attributesTabContent";
import ComponentLoader from "../../../common/loader/componentLoader";
import { ModalActionType } from "../../../models/store/unsavedChangesDataState";
import OrderAgeInterval from "../../../models/filters/orderAgeInterval";

export default function QueueSetting() {
  const [tabValue, setTabValue] = useState(0);
  const [queueSetting, setQueueSetting] = useState<QueueSettingModel>();
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [isStatusExist, setIsStatusExist] = useState<boolean>(true);
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>(
    []
  );

  const {
    isChanged: isSettingChanged,
    isFiltersCustomValuesModified: isFiltersCustomValuesChanged,
    hasOrderAgesError,
  } = useSelector((state: AppState) => ({
    isChanged: state.unsavedChangesDataState.isChanged,
    isFiltersCustomValuesModified:
      state.unsavedChangesDataState.isFiltersCustomValuesModified,
    hasOrderAgesError: state.unsavedChangesDataState.hasOrderAgesError,
  }));
  const [filters, setFilters] = useState<FilterModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [orderAgesIntervals, setOrderAgesInterval] = useState<
    OrderAgeInterval[]
  >([]);

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const numberOnlyRegExp = /^\d+$/;
  const requiredProps = [
    "queueGroupId",
    "filters",
    "targetRate",
    "threshold",
    "minVolume",
    "maxVolume",
  ];

  const getAllQueuesCallBack = useCallback(async () => {
    let queues: QueuesGroup[] = [];
    queues = await getAllQueues();
    dispatch(setQueues(queues));
  }, []);

  useEffect(() => {
    setValidationErrors([
      {
        isValid: true,
        propName: requiredProps[0],
        message: appConfig.Messages.required,
      },
      {
        isValid: true,
        propName: requiredProps[1],
        message: appConfig.Messages.required,
      },
      {
        isValid: true,
        propName: requiredProps[2],
        message: appConfig.Messages.required,
      },
      {
        isValid: true,
        propName: requiredProps[3],
        message: appConfig.Messages.required,
      },
      {
        isValid: true,
        propName: requiredProps[4],
        message: appConfig.Messages.required,
      },
      {
        isValid: true,
        propName: requiredProps[5],
        message: appConfig.Messages.required,
      },
    ]);

    getAllFilters();

    return () => {
      dispatch(isChanged(false));
    };
  }, []);

  const getAllFilters = async () => {
    const filters = await getFilters();
    setFilters(filters);
  };

  const editValidationErrors = (
    name: string,
    message: string = appConfig.Messages.required,
    isValid: boolean = false
  ) => {
    const prop = validationErrors.find((p) => p.propName === name);
    if (prop) {
      prop.isValid = isValid;
      prop.message = message;
      setValidationErrors([...validationErrors]);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const getQueueSettingByStatusId = useCallback(async () => {
    if (id && Number(id)) {
      try {
        setIsLoading(true);
        const setting = await getQueueSetting(+id);
        setIsLoading(false);

        setIsStatusExist(setting.success);

        if (setting.result?.filterIds.length === 0) {
          setting.result.filterIds[0] = 0;
        }
        setQueueSetting(setting.result);
      } catch {
        setIsLoading(false);
      }
    }
  }, [id]);

  useEffect(() => {
    getQueueSettingByStatusId();
  }, [getQueueSettingByStatusId]);

  const handleArrowBack = () => {
    if (isSettingChanged || isFiltersCustomValuesChanged) {
      const modalActionType: ModalActionType = ModalActionType.navigate;
      const modalActionData = "/queuesSetup";
      dispatch(
        openConfirmModal({
          modalConfirmedActionType: modalActionType,
          modalConfirmedActionData: modalActionData,
        })
      );
    } else navigate("/queuesSetup");
  };
  const isGeneralInfoValid = () => {
    if (!queueSetting?.queueGroupId) {
      const queueGroupIdProp = validationErrors.find(
        (p) => p.propName === requiredProps[0]
      );

      if (queueGroupIdProp) queueGroupIdProp.isValid = false;
      setValidationErrors([...validationErrors]);

      return false;
    }

    return true;
  };

  const areFiltersValid = () => {
    if (queueSetting?.isSynced) {
      const filtersProp = validationErrors.find(
        (p) => p.propName === requiredProps[1]
      );
      const validFilters = queueSetting.filterIds.filter((p) => p !== 0);
      if (validFilters.length === 0) {
        if (filtersProp) filtersProp.isValid = false;
        setValidationErrors([...validationErrors]);

        return false;
      }
    }
    return true;
  };

  const checkValidationsOfTargetRate = (valid: boolean) => {
    // #region TargetRate validations
    const targetRate =
      queueSetting?.queueConfig?.individualTargetRate?.toString() ?? "";

    if (targetRate.length === 0 && !queueSetting?.isSynced) {
      editValidationErrors(requiredProps[2], "", true);
    } else if (targetRate.length === 0) {
      editValidationErrors(requiredProps[2], appConfig.Messages.required);
      valid = false;
    } else if (!numberOnlyRegExp.test(targetRate)) {
      editValidationErrors(requiredProps[2], appConfig.Messages.decimalOnly);
      valid = false;
    } else if (targetRate.length > 5) {
      editValidationErrors(requiredProps[2], appConfig.Messages.maxFive);
      valid = false;
    }

    return valid;
    // #endregion
  };

  const checkValidationsOfThreshold = (valid: boolean) => {
    const threshold =
      queueSetting?.queueConfig?.thresholdTimeoutInMinutes?.toString() ?? "";

    if (threshold.length === 0 && !queueSetting?.isSynced) {
      editValidationErrors(requiredProps[3], "", true);
    } else if (threshold.length === 0) {
      editValidationErrors(requiredProps[3], appConfig.Messages.required);
      valid = false;
    } else if (!numberOnlyRegExp.test(threshold)) {
      editValidationErrors(requiredProps[3], appConfig.Messages.decimalOnly);
      valid = false;
    } else if (threshold.length > 2) {
      editValidationErrors(requiredProps[3], appConfig.Messages.maxTwo);
      valid = false;
    } else if (threshold.length === 2 && +threshold > 60) {
      editValidationErrors(requiredProps[3], appConfig.Messages.maxMinutes);
      valid = false;
    }

    return valid;
  };

  const checkValidationsOfMinVolume = (
    valid: boolean,
    minVolume: string,
    maxVolume: string
  ) => {
    // #region MinVolume validations

    if (minVolume.length === 0 && !queueSetting?.isSynced) {
      editValidationErrors(requiredProps[4], "", true);
    } else if (minVolume.length === 0) {
      editValidationErrors(requiredProps[4], appConfig.Messages.required);
      valid = false;
    } else if (!numberOnlyRegExp.test(minVolume)) {
      editValidationErrors(requiredProps[4], appConfig.Messages.decimalOnly);
      valid = false;
    } else if (minVolume.length > 5) {
      editValidationErrors(requiredProps[4], appConfig.Messages.maxFive);
      valid = false;
    } else if (maxVolume.length > 0 && +minVolume > +maxVolume) {
      editValidationErrors(requiredProps[4], appConfig.Messages.minLessThanMax);
      editValidationErrors(requiredProps[5], appConfig.Messages.minLessThanMax);
      valid = false;
    }
    // #endregion
    return valid;
  };

  const checkValidationsOfMaxVolume = (
    valid: boolean,
    minVolume: string,
    maxVolume: string
  ) => {
    // #region MaxVolume validations
    if (maxVolume.length === 0 && !queueSetting?.isSynced) {
      editValidationErrors(requiredProps[5], "", true);
    } else if (maxVolume.length === 0) {
      editValidationErrors(requiredProps[5], appConfig.Messages.required);
      valid = false;
    } else if (!numberOnlyRegExp.test(maxVolume)) {
      editValidationErrors(requiredProps[5], appConfig.Messages.decimalOnly);
      valid = false;
    } else if (maxVolume.length > 5) {
      editValidationErrors(requiredProps[5], appConfig.Messages.maxFive);
      valid = false;
    }

    if (!queueSetting?.isSynced) {
      if (maxVolume.length < minVolume.length) {
        editValidationErrors(
          requiredProps[4],
          appConfig.Messages.minLessThanMax
        );
        editValidationErrors(
          requiredProps[5],
          appConfig.Messages.minLessThanMax
        );
        valid = false;
      }
    }
    return valid;
  };

  const isQueueAttributeValid = () => {
    let valid = true;
    // #region TargetRate validations
    valid = checkValidationsOfTargetRate(valid);
    // #endregion

    // #region Threshold  validations
    valid = checkValidationsOfThreshold(valid);
    // #endregion

    const minVolume = queueSetting?.queueConfig?.minVolume?.toString() ?? "";

    const maxVolume = queueSetting?.queueConfig?.maxVolume?.toString() ?? "";

    // #region MinVolume validations
    valid = checkValidationsOfMinVolume(valid, minVolume, maxVolume);
    // #endregion

    // #region MaxVolume validations
    valid = checkValidationsOfMaxVolume(valid, minVolume, maxVolume);
    // #endregion
    return valid;
  };

  const handleQueueConfig = (updatedQueueSetting: QueueSettingModel) => {
    if (updatedQueueSetting.queueConfig != null)
      updatedQueueSetting.queueConfig = {
        individualTargetRate: updatedQueueSetting.queueConfig
          .individualTargetRate
          ? updatedQueueSetting.queueConfig.individualTargetRate
          : undefined,
        thresholdTimeoutInMinutes: updatedQueueSetting.queueConfig
          .thresholdTimeoutInMinutes
          ? updatedQueueSetting.queueConfig.thresholdTimeoutInMinutes
          : undefined,
        maxVolume: updatedQueueSetting.queueConfig.maxVolume
          ? updatedQueueSetting.queueConfig.maxVolume
          : undefined,
        minVolume: updatedQueueSetting.queueConfig.minVolume
          ? updatedQueueSetting.queueConfig.minVolume
          : undefined,
      };
  };

  const handleSaveChange = async () => {
    const validGeneralInfo = isGeneralInfoValid();
    const validFilters = areFiltersValid();
    const validAttribute = isQueueAttributeValid();
    if (!validGeneralInfo) {
      setTabValue(0);
    } else if (!validFilters || hasOrderAgesError) setTabValue(1);
    else if (!validAttribute) setTabValue(2);

    if (
      queueSetting &&
      validGeneralInfo &&
      validFilters &&
      validAttribute &&
      !hasOrderAgesError
    ) {
      try {
        setSaveLoading(true);
        const updatedQueueSetting: QueueSettingModel = JSON.parse(
          JSON.stringify(queueSetting)
        );

        if (isSettingChanged) {
          updatedQueueSetting.filterIds = updatedQueueSetting.filterIds.filter(
            (p) => p !== 0
          );
          handleQueueConfig(updatedQueueSetting);

          await updateQueueSetting(updatedQueueSetting);
        }

        if (isFiltersCustomValuesChanged) {
          await updateOrderAges({
            queueId: updatedQueueSetting.queueId,
            updateOrderAgesModel: {
              orderAges: orderAgesIntervals,
            },
          });
        }

        setSaveLoading(false);
        dispatch(
          showSnackbarBySeverity({
            message: "Queue Settings are updated successfully",
            severity: "success",
          })
        );
        dispatch(isChanged(false));
        dispatch(isFiltersCustomValuesModified(false));
        navigate("../queuesSetup");
        getAllQueuesCallBack();
      } catch (e) {
        setSaveLoading(false);
        dispatch(
          showSnackbarBySeverity({
            message: "Failed to update Queue Settings",
            severity: "error",
          })
        );
      }
    }
  };
  return (
    <ComponentLoader isOpen={isLoading}>
      {!isStatusExist ? (
        <QueueNotFound />
      ) : (
        <>
          <Box
            display={"flex"}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              <ArrowBackIcon
                sx={{ cursor: "pointer", pointerEvents: "auto" }}
                onClick={handleArrowBack}
              />

              <Typography sx={statusTitle}>
                {queueSetting?.statusName}
              </Typography>
            </Box>

            <Button
              type="submit"
              variant="contained"
              sx={{ ...submitButton, pointerEvents: "auto" }}
              data-auto-test-id="submit-queue-form-button"
              disabled={!isSettingChanged && !isFiltersCustomValuesChanged}
              onClick={handleSaveChange}
            >
              {saveLoading ? (
                <CircularProgress color="inherit" size={"2rem"} />
              ) : (
                "Save Changes"
              )}
            </Button>
          </Box>

          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              sx={{ pointerEvents: "auto" }}
            >
              <StyledTab
                data-auto-test-id="queue-setting-tab"
                label="General"
                disabled={false}
              />
              <StyledTab
                data-auto-test-id="filters-setting-tab"
                label="Filters"
                disabled={false}
              />
              <StyledTab
                data-auto-test-id="attributes-setting-tab"
                label="Attributes"
                disabled={false}
              />
            </Tabs>
          </Box>
          <TabPanel
            data-auto-test-id="general-panel"
            value={tabValue}
            index={0}
          >
            <GeneralTabContent
              queueSetting={queueSetting}
              setQueueSetting={setQueueSetting}
              validationErrors={validationErrors}
              requiredProps={requiredProps}
              setValidationErrors={setValidationErrors}
            />
          </TabPanel>
          <TabPanel
            data-auto-test-id="filters-panel"
            value={tabValue}
            index={1}
          >
            <FiltersTabContent
              queueSetting={queueSetting}
              setQueueSetting={setQueueSetting}
              validationErrors={validationErrors}
              filters={filters}
              requiredProps={requiredProps}
              setValidationErrors={setValidationErrors}
              orderAgesIntervals={orderAgesIntervals}
              setOrderAgesIntervals={setOrderAgesInterval}
            />
          </TabPanel>

          <TabPanel
            data-auto-test-id="attribute-panel"
            value={tabValue}
            index={2}
          >
            <AttributesTabContent
              queueSetting={queueSetting}
              setQueueSetting={setQueueSetting}
              validationErrors={validationErrors}
              requiredProps={requiredProps}
              editValidationErrors={editValidationErrors}
            />
          </TabPanel>
        </>
      )}
    </ComponentLoader>
  );
}
