import React from "react";
import {
  Dialog as MuiDialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  Grid,
  DialogContentText,
} from "@mui/material";

interface ConfirmDialogProps {
  text: string;
  onConfirm: () => Promise<void>;
  onClose: () => void;
  isOpen: boolean;
  loading: boolean;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  text,
  onConfirm,
  onClose,
  isOpen,
  loading,
}) => {
  return (
    <MuiDialog open={isOpen} onClose={onClose}>
      <DialogContent>
        <Box sx={{ width: "400px" }}>
          <Grid container justifyContent="center">
            <Grid container justifyContent="center" alignItems="center"></Grid>
          </Grid>
          <Typography mt={1} align="center" fontWeight="bold">
            Confirm Action!
          </Typography>
          <Box>
            <DialogContentText align="center" mt={1}>
              {text}
            </DialogContentText>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
          disabled={loading}
          fullWidth
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={onConfirm}
          disabled={loading}
          fullWidth
          sx={{ color: "white" }}
        >
          Confirm
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};

export default ConfirmDialog;
