import { SxProps } from "@mui/material";

export const Title: SxProps = {
  fontWeight: 700,
  fontSize: "32px",
  lineHeight: "48px",
  color: (theme: any) => theme.palette.neutral["800"],
};

export const SubTitle: SxProps = {
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "24px",
  color: (theme: any) => theme.palette.neutral["800"],
  marginTop: "13px",
};

export const badge: SxProps = {
  borderRadius: "24px",
  px: 1,
  py: "4px",
  marginLeft: "14px",
  fontSize: "14px",
  fontWeight: 400,
  backgroundColor: (theme: any) => theme.palette.primary["100"],
  color: (theme: any) => theme.palette.primary["500"],
};

export const pagination: SxProps = {
  borderTop: "0px",
};

export const editButton: SxProps = {
  padding: 0, 
  minWidth: 'auto'
};