import FilterLookupItem from "../../../models/filters/filterLookupItem";
import FilterDependency from "../../../models/filters/filterDependency";
import QueueFilterSkeleton from "../../../models/filters/queueFilterSkeleton";
import appConfig from "../../../settings/appConfig";
import { get, post, put } from "../../http/apiClient";
import FilterModel from "../../../models/filters/filterModel";
import OrderAgeInterval from "../../../models/filters/orderAgeInterval";
import UpdateFiltersCustomValuesModel from "../../../models/filters/UpdateFiltersCustomValuesModel";
export const getFilterLookupItems = async (
  queueId: string,
  filterDependencies: FilterDependency[]
): Promise<FilterLookupItem[]> => {
  try {
    if (filterDependencies.length > 0) {
      let currentFilter = filterDependencies[filterDependencies.length - 1];
      let request = {
        queueId,
        dependencyFilterValues: filterDependencies
          .slice(0, filterDependencies.length - 1)
          .map((v) => ({
            value: v.value?.toString() ?? null,
            filterId: v.filterId,
          })),
        queueFilterId: currentFilter.queueFilterId,
      };
      const response = await post(
        `${appConfig.baseUrl}${appConfig.URLS.Queue.GetLookupdata}`,
        request
      );
      return (response as FilterLookupItem[])?.sort((a, b) => a.id - b.id);
    }
    return [];
  } catch (error: any) {
    throw error;
  }
};

export const getFiltersSkeleton = async (
  queueId: string
): Promise<QueueFilterSkeleton[]> => {
  try {
    const response = await get(
      `${appConfig.baseUrl}${appConfig.URLS.Queue.GetQueueFilters}?id=${queueId}`
    );
    return (response as QueueFilterSkeleton[])?.sort(
      (a, b) => a.ordinal - b.ordinal
    );
  } catch (error: any) {
    throw error;
  }
};

export const getFilters = async (): Promise<FilterModel[]> => {
  try {
    const response = await get(
      `${appConfig.baseUrl}${appConfig.URLS.Filter.GetAll}`
    );
    return response as FilterModel[];
  } catch (error: any) {
    throw error;
  }
};

export const getOrderAges = async (
  queueId: number
): Promise<OrderAgeInterval[]> => {
  try {
    const response = await get(
      `${appConfig.baseUrl}${appConfig.URLS.Filter.GetOrderAges}?queueId=${queueId}`
    );
    const orderAgeInterval = response as OrderAgeInterval[];
    return orderAgeInterval;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateOrderAges = async (
  updateFiltersCustomValuesModel: UpdateFiltersCustomValuesModel
) => {
  try {
    await put(
      `${appConfig.baseUrl}${appConfig.URLS.Filter.UpdateFiltersCustomValues}`,
      updateFiltersCustomValuesModel
    );
  } catch (error: any) {
    console.error(error);
    throw error;
  }
};
