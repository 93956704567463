import {
  Box,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import QueueSettingModel from "../../../../../models/status/queueSettingModel";
import { useCallback, useEffect } from "react";
import ValidationError from "../../../../../models/status/validationError";
import { isChanged } from "../../../../../redux/slices/unsavedChangesDataSlice";
import { useDispatch } from "react-redux";
import FilterModel from "../../../../../models/filters/filterModel";
import formatAttribute from "../../../../../common/formatAttribute";
import { closeIcon, label } from "../../queueSetting.style";
import CloseIcon from "@mui/icons-material/Close";
import OrderAgeIntervals from "./components/orderAgeIntervals";
import OrderAgeInterval from "../../../../../models/filters/orderAgeInterval";
import { FiltersEnum } from "../../../../../models/enums/filtersEnum";

interface CustomizeButtonProps {
  filterId: FiltersEnum;
  filters: FilterModel[];
  orderAgesIntervals: OrderAgeInterval[];
  setOrderAgesIntervals: React.Dispatch<
    React.SetStateAction<OrderAgeInterval[]>
  >;
}

export const CustomizeButton: React.FC<CustomizeButtonProps> = ({
  filterId,
  filters,
  orderAgesIntervals,
  setOrderAgesIntervals,
}) => {
  const dispatch = useDispatch();
  if (!filters.find((f) => f.filterId === filterId)?.hasCustomValues)
    return null;

  let button: JSX.Element | null = null;

  switch (filterId) {
    case FiltersEnum.OrderAge:
      button =
        orderAgesIntervals.length === 0 ? (
          <Button
            variant="outlined"
            sx={{
              height: "40px",
            }}
            onClick={() => {
              dispatch(isChanged(true));
              setOrderAgesIntervals([{ from: 0, to: null }]);
            }}
          >
            Customize Age Buckets
          </Button>
        ) : null;
  }

  return <span data-testid={`customize-button`}>{button}</span>;
};

interface CustomComponentProps {
  filterId: FiltersEnum;
  queueSetting?: QueueSettingModel;
  orderAgesIntervals?: OrderAgeInterval[];
  setOrderAgesIntervals?: React.Dispatch<
    React.SetStateAction<OrderAgeInterval[]>
  >;
}

export const CustomComponent: React.FC<CustomComponentProps> = ({
  filterId,
  queueSetting,
  orderAgesIntervals,
  setOrderAgesIntervals,
}) => {
  switch (filterId) {
    case FiltersEnum.OrderAge:
      return orderAgesIntervals && setOrderAgesIntervals ? (
        <OrderAgeIntervals
          queueSetting={queueSetting}
          intervals={orderAgesIntervals}
          setIntervals={setOrderAgesIntervals}
        />
      ) : null;
    default:
      return null;
  }
};

export default function FiltersTabContent({
  queueSetting,
  setQueueSetting,
  validationErrors,
  filters,
  requiredProps,
  setValidationErrors,
  orderAgesIntervals,
  setOrderAgesIntervals,
}: {
  readonly queueSetting: QueueSettingModel | undefined;
  readonly setQueueSetting: any;
  readonly validationErrors: ValidationError[];
  readonly filters: FilterModel[];
  readonly requiredProps: string[];
  readonly setValidationErrors: any;
  readonly orderAgesIntervals: OrderAgeInterval[];
  readonly setOrderAgesIntervals: React.Dispatch<
    React.SetStateAction<OrderAgeInterval[]>
  >;
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (queueSetting?.filterIds.length === 0) {
      addAnother();
    }
  }, [queueSetting?.filterIds]);

  const onFilterChanged = (e: SelectChangeEvent<number>, index: number) => {
    if (queueSetting) {
      queueSetting.filterIds[index] = +e.target.value;
      setQueueSetting({
        ...queueSetting,
        queueFilterIds: [...queueSetting.filterIds],
      });
      dispatch(isChanged(true));

      const queueGroupIdProp = validationErrors.find(
        (p) => p.propName === requiredProps[1]
      );

      if (queueGroupIdProp) queueGroupIdProp.isValid = true;
      setValidationErrors([...validationErrors]);
    }
  };

  const addAnother = useCallback(() => {
    if (queueSetting) {
      queueSetting?.filterIds.push(0);
      setQueueSetting({
        ...queueSetting,
        queueFilterIds: [...queueSetting.filterIds],
      });
    }
  }, [queueSetting]);

  const getAvailableFilters = useCallback(
    (index: number) => {
      return filters.filter(
        (p) =>
          !queueSetting?.filterIds.includes(p.filterId) ||
          queueSetting?.filterIds[index] === p.filterId
      );
    },
    [filters, queueSetting?.filterIds]
  );

  const removeFilter = useCallback(
    (index: number) => {
      if (queueSetting) {
        queueSetting.filterIds = queueSetting.filterIds.filter(
          (item, i) => i !== index
        );

        setQueueSetting({
          ...queueSetting,
          queueFilterIds: [...queueSetting.filterIds],
        });

        dispatch(isChanged(true));
      }
    },
    [queueSetting]
  );
  return (
    <Box>
      {queueSetting?.filterIds.map((filterId, index) => (
        <Box key={`box-${filterId}`}>
          <Box sx={{ marginTop: "30px" }} key={`box-${filterId}`}>
            {index === 0 ? (
              <Typography data-auto-test-id="first-filter" sx={label}>
                First Filter
              </Typography>
            ) : (
              <Typography data-auto-test-id="next-filter" sx={label}>
                Next Filter
              </Typography>
            )}

            <Box display={"flex"} alignItems={"start"} gap={4}>
              <Box display={"flex"} gap={2} alignItems="center">
                <Box sx={{ minWidth: "400px" }}>
                  <Select
                    key={`select-${filterId}`}
                    fullWidth
                    size="small"
                    value={filterId ?? "0"}
                    onChange={(e) => onFilterChanged(e, index)}
                    data-auto-test-id="select-queue-group-drop-down"
                  >
                    <MenuItem key="0" disabled value="0">
                      Select
                    </MenuItem>
                    {getAvailableFilters(index).map((filter) => (
                      <MenuItem
                        key={filter.filterName}
                        value={filter.filterId}
                        data-auto-test-id={
                          formatAttribute(filter.filterName ?? "") +
                          "-menu-item"
                        }
                      >
                        {filter.filterName}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                {(index > 0 || !queueSetting.isSynced) &&
                  queueSetting.filterIds[index] !== 0 && (
                    <CloseIcon
                      sx={closeIcon}
                      data-testid={`close-icon-${index}`}
                      onClick={() => removeFilter(index)}
                    />
                  )}
                <CustomizeButton
                  filterId={filterId}
                  filters={filters}
                  orderAgesIntervals={orderAgesIntervals}
                  setOrderAgesIntervals={setOrderAgesIntervals}
                />
              </Box>
              <CustomComponent
                filterId={filterId}
                queueSetting={queueSetting}
                orderAgesIntervals={orderAgesIntervals}
                setOrderAgesIntervals={setOrderAgesIntervals}
              />
            </Box>
          </Box>

          <Typography
            data-auto-test-id="filter-error-text"
            component="span"
            color={"error"}
            display={"block"}
            key={`error-${filterId}`}
          >
            {validationErrors.find((p) => p.propName === requiredProps[1])
              ?.isValid
              ? ""
              : validationErrors.find((p) => p.propName === requiredProps[1])
                  ?.message}
          </Typography>
          {index === queueSetting?.filterIds.length - 1 &&
            queueSetting?.filterIds.length < filters.length &&
            queueSetting?.filterIds[index] !== 0 && (
              <Button
                variant="contained"
                sx={{ marginTop: "30px", color: "white" }}
                onClick={addAnother}
                key={`button-${filterId}`}
              >
                Add Another
              </Button>
            )}
        </Box>
      ))}
    </Box>
  );
}
