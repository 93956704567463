import { Typography } from "@mui/material";
import { OrdersIntegrationProps } from "../../models/ordersIntegration/OrdersIntegrationProps";
import OrdersTable from "./components/ordersTable";
import styles from "./ordersIntegration.style";

export const OrdersIntegration: React.FC<OrdersIntegrationProps> = (
  ordersIntegrationProps: OrdersIntegrationProps
) => {
  return (
    <>
      <Typography sx={styles.ordersType}>
        {`${
          ordersIntegrationProps.isMatched ? "Matched" : "Mismatched"
        } Orders`}
      </Typography>

      <OrdersTable {...ordersIntegrationProps} />
    </>
  );
};
