export interface UnsavedChangesDataState {
  isChanged: boolean;
  isFiltersCustomValuesModified: boolean;
  hasOrderAgesError: boolean;
  openModal: boolean;
  modalConfirmedActionType: ModalActionType;
  modalConfirmedActionData: string;
}

export interface OpenConfirm {
  modalConfirmedActionType: ModalActionType;
  modalConfirmedActionData: string;
}

export enum ModalActionType {
  showSnackbar = "showSnackbar",
  navigate = "navigate",
}
