import { msalConfig } from "../../settings/authConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import axios, { AxiosInstance } from "axios";
import appConfig from "../../settings/appConfig";
import { refreshToken, getPersistAuthData } from "../services/auth/authService";

const msalInstance = new PublicClientApplication(msalConfig);

const createAxiosClient = (): AxiosInstance => {
  const client: AxiosInstance = axios.create({
    baseURL: appConfig.baseUrl,
    responseType: "json" as const,
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    timeout: window._env_.AXIOS_CLIENT_TIMEOUT as number,
  });

  return client;
};

const interceptAxios = (client: AxiosInstance) => {
  client.interceptors.request.use(
    (config) => {
      if (
        config !== undefined &&
        config.headers !== undefined &&
        !config.headers["Authorization"]
      ) {
        const authData = getPersistAuthData();
        config.headers["Authorization"] = `Bearer ${authData?.token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  client.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response) {
        const prevRequest = error?.config;
        if (error?.response?.status === 401 && !prevRequest?._retry) {
          try {
            const newAuth = await refreshToken(msalInstance);
            prevRequest.headers[
              "Authorization"
            ] = `Bearer ${newAuth.accessToken}`;
            prevRequest._retry = true;
            // return a request
            return client(prevRequest);
          } catch (_error: any) {
            if (_error?.response?.data) {
              return Promise.reject(_error.response.data);
            }
            return Promise.reject(_error);
          }
        } else if (error?.response?.status === 409) {
          return Promise.reject(error);
        }
      }
    }
  );
};

let axiosClient = createAxiosClient();
interceptAxios(axiosClient);

export default axiosClient;
