import { SxProps, Theme } from "@mui/material";

export const styles = {
  paper: {
    width: "100%",
    maxWidth: 1600,
    margin: "20px auto",
    position: "absolute",
    borderRadius: 2,
    boxShadow: 3,
  } as SxProps,

  tableContainer: {
    height: 620,
    overflowY: "auto",
  } as SxProps,

  stickyTableHead: {
    "& tr": {
      backgroundColor: "grey.100",
    },
    "& th": {
      fontWeight: "bold",
      position: "sticky",
      top: 0,
      backgroundColor: "grey.100",
      zIndex: 1,
    },
  } as SxProps,

  emptyStateContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 4,
    textAlign: "center",
  } as SxProps,

  actionButtonGroup: {
    position: "absolute",
    top: 10,
    right: 10,
  } as SxProps,

  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 300,
  } as SxProps,

  filterContainer: {
    padding: 2,
  } as SxProps,

  filterGrid: {
    spacing: 2,
    alignItems: "center",
  } as SxProps,
};
