import { SxProps } from "@mui/material";

const ordersType: SxProps = {
  fontWeight: 700,
  fontSize: "24px",
  lineHeight: "36px",
  color: (theme: any) => theme.palette.neutral["800"],
  marginTop: "8px",
};

const classes = {
  ordersType: ordersType,
};

export default classes;
